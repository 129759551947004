import React from "react";
import ReactQuill from "react-quill";
import "./EditorStyles.css";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
function Editor({ defaultValue, value, onChange, onBlur }) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }], // Header options
      ["bold", "italic", "underline"], // Font styling
      ["link"], // Link and image
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      ["clean"] // Remove formatting button
    ]
  };
  return (
    <ReactQuill
      id="#editor"
      className="quill-editor"
      theme={"snow"}
      modules={modules}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

export default Editor;
