import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Pebble, PebbleText } from "../JobDetailDialog";
function JobSkillsRequired({ jobSkills, hiddenSkills, showHiddenSkills, setShowHiddenSkills }) {
  return (
    <Box mb={2}>
      <Typography gutterBottom variant="subtitle2" fontWeight={"bold"}>
        Skills Required
      </Typography>
      <Grid container display={"flex"} gap={2} alignItems={"center"}>
        {jobSkills.length === 0 && "No skills specified"}
        {jobSkills.length > 0 &&
          jobSkills.slice(0, 3).map((benefit) => (
            <Pebble
              key={benefit}
              sx={{
                textAlign: "center"
              }}
            >
              <PebbleText>{benefit}</PebbleText>
            </Pebble>
          ))}
        {showHiddenSkills &&
          hiddenSkills.map((skill) => (
            <Pebble
              key={skill}
              sx={{
                textAlign: "center"
              }}
            >
              <PebbleText>{skill}</PebbleText>
            </Pebble>
          ))}
        {jobSkills.length > 4 && (
          <Pebble
            onClick={() => setShowHiddenSkills(true)}
            sx={{
              cursor: "pointer",
              ":hover": { backgroundColor: "lightgray" },
              transition: "background .5s, color .5s",
              display: `${showHiddenSkills ? "none" : "block"}`
            }}
          >
            <PebbleText>{`+${jobSkills.length - 3} More`}</PebbleText>
          </Pebble>
        )}
      </Grid>
    </Box>
  );
}

export default JobSkillsRequired;
