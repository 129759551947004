import { Place } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDebounce } from "app/hooks/useDebounce";
function FilterByLocation({ onChange }) {
  const [location, setLocation] = useState("");
  const debounceLocation = useDebounce(location, 500);

  useEffect(() => {
    onChange(debounceLocation);
  }, [debounceLocation]);
  return (
    <Box>
      {/* <Autocomplete
        value={location}
        onChange={(event, newValue) => {
          setLocation(newValue);
          onChecked(newValue);
        }}
        getOptionLabel={(option) => option.label}
        size="small"
        sx={{ minWidth: "320px" }}
        options={LocationOptions}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Location Type" />
        )}
      /> */}
      <TextField
        label={"Search by city"}
        value={location}
        onChange={(event) => {
          setLocation(event.target.value);
        }}
        size={"small"}
        sx={{ minWidth: "300px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Place />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default FilterByLocation;
