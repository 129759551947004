import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { ExpFilterOptions } from "../../Constants";
import { Work } from "@mui/icons-material";

function FilterByExp({ onChecked }) {
  return (
    <Box>
      <Autocomplete
        onChange={(event, newValue) => {
          onChecked(newValue);
        }}
        getOptionLabel={(option) => option.label}
        sx={{ flex: 1, minWidth: "300px" }}
        size="small"
        options={ExpFilterOptions}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Experience Level" />
        )}
      />
    </Box>
  );
}

export default FilterByExp;
