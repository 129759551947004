import React from "react";
import { Box, Button } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
function FormUploadFile({
  file,
  fileType,
  name,
  helperText,
  register,
  errors,
  onUploadFile,
  preloadedData
}) {
  let borderColor = "blue";
  if (errors && (errors.type === "required" || errors.type === "custom")) {
    borderColor = "red";
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      sx={{
        border: `2px dashed ${borderColor}`,
        height: "180px",
        transition: "background-color 0.3s",
        textAlign: "center",
        "&:hover": { backgroundColor: "#E0E0E0" }
      }}
      onClick={(event) => {
        event = event || window.event;
        if (event.target.id !== `file_upload_${name}`) {
          document.querySelector(`#file_upload_${name}`).click();
        }
      }}
    >
      <Box textAlign={"center"} maxWidth={"80%"}>
        <input
          type="file"
          name={name}
          id={`file_upload_${name}`}
          accept={fileType}
          {...register(`${name}`, { required: preloadedData ? undefined : helperText })}
          aria-invalid={errors ? "true" : "false"}
          style={{ display: "none" }}
          onChange={(event) => {
            onUploadFile(event.target.files[0]);
          }}
        />
      </Box>
      <Button sx={{ display: "flex", flexDirection: "column", ":hover": { background: "none" } }}>
        <UploadFile fontSize="large" htmlColor="#1475cf" sx={{ width: "90px", height: "90px" }} />
        <span style={{ color: errors ? "red" : "#1475cf" }}>{file ? file.name : "Upload"}</span>
      </Button>
      {errors && errors.type === "custom" && (
        <Typography color={"error"} mx={1}>
          {errors.message}
        </Typography>
      )}
      {errors && errors.type === "required" && !file && (
        <Typography color={"error"} mx={1}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default FormUploadFile;
