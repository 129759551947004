import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import JobDetailTitle from "./JobDetailTitle";
import JobDetailWrapper from "./JobDetailWrapper";
import { LocationCityRounded } from "@mui/icons-material";
function JobLocation({ cities }) {
  return (
    <JobDetailWrapper>
      <LocationCityRounded />
      <Box>
        <JobDetailTitle>Location</JobDetailTitle>
        <Box display={"flex"} gap={2}>
          {cities.length > 0 ? (
            <Grid container>
              {cities.map((city) => (
                <Grid item xs={6}>
                  <Typography key={city} variant="body2" color={"gray"}>
                    {city}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No location specified</Typography>
          )}
        </Box>
      </Box>
    </JobDetailWrapper>
  );
}

export default JobLocation;
