import { InfoRounded } from "@mui/icons-material";
import Send from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Tooltip
} from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import { isCompanyExists } from "app/api/company_api";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import FormTextField from "app/components/form-components/FormTextField";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import { Span } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import {
  CINRegex,
  EmailRegex,
  filterAlphabets,
  isValidURL,
  PhoneRegex
} from "app/utils/global_constants";
import { Industries } from "app/utils/selection_constants";
import {
  convertToBase64,
  createObjectURLFromFile,
  getCurrentTimeStamp,
  isFileLimitExceeded
} from "app/utils/utils";
import UploadFileTitle from "app/views/candidate/shared/UploadFileTitle";
import ContentBox from "app/views/jobs/shared/ContentBox";
import dayjs from "dayjs";
import { useMemo, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  CompanyLocationReducer,
  createInitialStateFromDB,
  InitialState
} from "../reducer/CountryStateCityReducer";
import PreviewLogo from "./PreviewLogo";
function getRandomNumber() {
  return Math.random() * 100;
}
function AddCompanyForm({ mutationFn, preloadedData, title = "", mutationState }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity
      }
    }
  });

  const location = useLocation();
  const isEdit =
    location.pathname.includes("company/listings") || location.pathname.includes("/profile");

  const [autocompleteKey, setAutocompleteKey] = useState({
    country: "country",
    state: getRandomNumber(),
    city: getRandomNumber()
  });

  const ReducerState = isEdit ? createInitialStateFromDB(preloadedData) : InitialState;
  const [CompanyLocation, dispatch] = useReducer(CompanyLocationReducer, ReducerState);

  const [logo, setLogo] = useState(preloadedData ? preloadedData.jp_logo : null);
  const previewLogo =
    logo instanceof File
      ? createObjectURLFromFile(logo)
      : `data:image/jpeg;base64,${convertToBase64(logo?.data)}`;

  const [cover, setCover] = useState(preloadedData ? preloadedData.jp_cover_photo : null);
  const previewCover =
    cover instanceof File
      ? createObjectURLFromFile(cover)
      : `data:image/jpeg;base64,${convertToBase64(cover?.data)}`;

  const [verifyRadio, setVerifyRadio] = useState(preloadedData ? preloadedData.jp_is_verified : 0);

  const [showLogoPreview, setShowLogoPreview] = useState(false);
  const [showCoverPreview, setShowCoverPreview] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      jp_state: CompanyLocation.state,
      jp_city: CompanyLocation.city
    }
  });

  const { user } = useAuth();

  const handleEmailBlur = async () => {
    const email = getValues("jp_email");
    if (!email) return;
    try {
      const isExists = await queryClient.fetchQuery({
        queryKey: ["is_company_exists"],
        queryFn: () => isCompanyExists(email)
      });
      if (isExists) {
        setError("jp_email", { type: "manual", message: "Email already exists" });
      } else {
        clearErrors("jp_email");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  const handleUploadLogo = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 1024 })) {
      alert("File size limit of 1 MB exceeded");
      setError(
        "jp_logo",
        { type: "custom", message: "File size limit of 1 MB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors();
      setLogo(file);
    }
  };

  const handleUploadCover = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 2548 })) {
      alert("File size limit of 2 MB exceeded");
      setError(
        "jp_cover_photo",
        { type: "custom", message: "File size limit of 2 MB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors();
      setCover(file);
    }
  };

  const onSubmitData = (data, event) => {
    const fd = new FormData(event.target);
    fd.set("jp_country", data.jp_country.label);
    fd.set("jp_state", data.jp_state.label);
    fd.set("jp_city", data.jp_city.label);
    fd.set(
      "jp_industry",
      typeof data.jp_industry === "string" ? data.jp_industry : data.jp_industry.label
    );

    if (
      (data.jp_website && !data.jp_website.includes("https://")) ||
      !data.jp_website.includes("http://")
    ) {
      fd.set("jp_website", `https://${data.jp_website}`);
    }

    if (location.pathname.includes("/company/addCompany")) {
      fd.set("jp_password", "Password123$");
      fd.set("jp_created_at", getCurrentTimeStamp());
      fd.set("jp_created_by", user.id);
    }
    if (location.pathname.includes("/company/listings")) {
      fd.set("jp_updated_at", getCurrentTimeStamp());
      fd.set("jp_updated_by", user.id);
    }
    if (data.jp_founded.toString() !== "Invalid Date") {
      fd.set("jp_founded", data.jp_founded.toISOString());
    } else {
      fd.delete("jp_founded");
    }

    mutationFn({ company: fd });
  };

  // If the company belongs to a non-standard industry category,
  // we need to include this industry as an option in the dropdown select.
  // Otherwise, the industry text field will remain empty.
  const IndustryOptions = useMemo(() => {
    const options = Industries;
    if (preloadedData) {
      const preloadedIndustry = preloadedData.jp_industry;
      if (preloadedIndustry) {
        options.push({ id: options.length + 1, label: preloadedIndustry });
      }
    }
    return options;
  }, [preloadedData]);

  return (
    <ContentBox>
      <h1>{title}</h1>
      {logo && (
        <PreviewLogo open={showLogoPreview} onClick={() => setShowLogoPreview(false)}>
          <Avatar src={previewLogo} sx={{ width: "200px", height: "200px" }} />
        </PreviewLogo>
      )}
      {cover && (
        <PreviewLogo open={showCoverPreview} onClick={() => setShowCoverPreview(false)}>
          <img style={{ width: "400px", height: "200px" }} src={previewCover} alt={"cover"} />
        </PreviewLogo>
      )}
      <form onSubmit={handleSubmit(onSubmitData)} encType="multipart/form-data">
        <Stack direction={"row"} gap={4}>
          <Box sx={{ width: 400 }}>
            <UploadFileTitle
              title={"Upload Logo *"}
              shouldPreview={previewLogo}
              actionTitle={isEdit ? "View" : logo ? "Preview" : ""}
              previewClick={() => {
                if (preloadedData || logo) {
                  setShowLogoPreview(true);
                }
              }}
            />
            <FormUploadFile
              file={logo}
              register={register}
              fileType={"image/*"}
              name={"jp_logo"}
              errors={errors.jp_logo}
              onUploadFile={handleUploadLogo}
              helperText={logo ? null : "Upload company logo"}
            />
          </Box>
          <Box sx={{ width: 400 }}>
            <UploadFileTitle
              title={"Upload Cover *"}
              actionTitle={isEdit ? "View" : cover ? "Preview" : ""}
              shouldPreview={previewCover}
              previewClick={() => {
                if (preloadedData || logo) {
                  setShowCoverPreview(true);
                }
              }}
            />
            <FormUploadFile
              file={cover}
              register={register}
              fileType={"image/*"}
              name={"jp_cover_photo"}
              errors={errors.jp_cover_photo}
              onUploadFile={handleUploadCover}
              helperText={cover ? null : "Upload cover photo"}
            />
          </Box>
        </Stack>
        {/* Company Name and CIN */}
        <Grid container spacing={2}>
          <Grid item md={6}>
            <h2>Add Company Name</h2>
            <FormTextField
              control={control}
              name={"jp_company_name"}
              defaultValue={preloadedData ? preloadedData.jp_company_name : ""}
              label={"Company Name *"}
              fullWidth={true}
              helperText={"Company name is required"}
            />
          </Grid>
          <Grid item md={6}>
            <h2>CIN</h2>
            <FormTextField
              control={control}
              name={"jp_cin"}
              defaultValue={preloadedData ? preloadedData.jp_cin : ""}
              label={"CIN (Corporate Identification Number) *"}
              fullWidth={true}
              rules={{ pattern: { value: CINRegex, message: "Enter valid CIN number" } }}
              helperText={"CIN is required"}
            />
          </Grid>
        </Grid>

        {/* Verify */}
        <h2>Verification Status</h2>
        <FormControl component={"fieldset"}>
          <RadioGroup
            row
            value={verifyRadio}
            onChange={(event) => setVerifyRadio(event.target.value)}
          >
            <FormControlLabel
              name={"jp_is_verified"}
              value={1}
              control={<Radio />}
              label="Verified"
            />
            <FormControlLabel
              name={"jp_is_verified"}
              value={0}
              control={<Radio />}
              label="Unverified"
            />
          </RadioGroup>
        </FormControl>
        <h2>Address</h2>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* Country */}
            <FormAutocomplete
              control={control}
              name={"jp_country"}
              defaultValue={preloadedData && preloadedData.jp_country}
              label={"Country *"}
              onChangeInput={(newValue) => {
                dispatch({ type: "SET_COUNTRY", payload: { country: newValue } });
                dispatch({ type: "UPDATE_STATE_OPTIONS", payload: { country: newValue } });
                dispatch({ type: "UPDATE_CITY", payload: { state: null } });
                if (newValue === null) {
                  setValue("jp_country", null);
                  setValue("jp_city", null);
                  setValue("jp_state", null);
                }
                setAutocompleteKey((prev) => ({
                  ...prev,
                  state: getRandomNumber(),
                  city: getRandomNumber()
                }));
              }}
              values={CompanyLocation.countryOptions}
              fullWidth
              sxValues={{ width: "100%" }}
              helperText={"Country is required"}
            />
          </Grid>
          <Grid item xs={4}>
            {/* State */}
            <FormAutocomplete
              key={autocompleteKey.state}
              control={control}
              name={"jp_state"}
              value={CompanyLocation.state}
              label={"State *"}
              onChangeInput={(newValue) => {
                dispatch({ type: "SET_STATE", payload: { state: newValue } });
                dispatch({ type: "UPDATE_CITY", payload: { state: newValue } });
                if (newValue === null) {
                  setValue("jp_state", null);
                  setValue("jp_city", null);
                }
                setAutocompleteKey((prev) => ({ ...prev, city: getRandomNumber() }));
              }}
              sxValues={{ width: "100%" }}
              values={CompanyLocation.stateOptions}
              helperText={"State is required"}
            />
          </Grid>
          <Grid item xs={4}>
            {/* City */}
            <FormAutocomplete
              key={autocompleteKey.city}
              control={control}
              name={"jp_city"}
              value={CompanyLocation.city}
              label={"City *"}
              values={CompanyLocation.cityOptions}
              onChangeInput={(newValue) =>
                dispatch({ type: "SET_CITY", payload: { city: newValue } })
              }
              sxValues={{ width: "100%" }}
              helperText={"City is required"}
            />
          </Grid>
        </Grid>
        <h2>Contact</h2>
        <Box display={"flex"} gap={4} justifyContent={"space-between"}>
          {/* Website */}
          <FormTextField
            control={control}
            name={"jp_website"}
            defaultValue={preloadedData && preloadedData.jp_website.split("https://").pop()}
            label={"Website link (https://www.example.com)"}
            rules={{ validate: isValidURL }}
            InputProps={{
              startAdornment: <InputAdornment position="start">https://</InputAdornment>
            }}
            fullWidth
          />
          {/* Email */}
          <FormTextField
            control={control}
            name={"jp_email"}
            defaultValue={preloadedData && preloadedData.jp_email}
            onBlur={handleEmailBlur}
            label={"Email *"}
            fullWidth
            rules={{ pattern: { value: EmailRegex, message: "Please enter a valid email" } }}
            helperText={"Email is required"}
          />
          <FormTextField
            control={control}
            name={"jp_contact_no"}
            defaultValue={preloadedData && preloadedData.jp_contact_no}
            label={"Phone Number *"}
            type={"text"}
            rules={{
              pattern: { value: PhoneRegex, message: "Please enter valid phone number" }
            }}
            inputProps={{ maxLength: 10 }}
            onInput={filterAlphabets}
            fullWidth
            helperText={"Phone number is required"}
          />
        </Box>
        <h2>About Company</h2>
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Box display={"flex"} gap={4} justifyContent={"space-evenly"}>
            {/* Foundation Date */}
            <FormDatePicker
              control={control}
              name={"jp_founded"}
              defaultValue={
                preloadedData && preloadedData.jp_founded ? preloadedData.jp_founded : ""
              }
              label={"Foundation Date *"}
              disableFuture
              rules={{
                validate: (fieldValue) => {
                  if (dayjs.isDayjs(fieldValue) && isNaN(fieldValue.$d.getTime())) {
                    return "Foundation date is required";
                  }
                  if (!dayjs(fieldValue).isValid()) {
                    return "Invalid foundation date";
                  }
                  return true;
                }
              }}
              fullWidth
            />
            {/* Industry */}
            <FormAutocomplete
              control={control}
              name={"jp_industry"}
              freeSolo={true}
              defaultValue={preloadedData && preloadedData.jp_industry}
              sxValues={{ width: "100%" }}
              values={IndustryOptions}
              onChange={(data) => setValue("jp_industry", data)}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <Tooltip
                      title={
                        "If an industry isn't listed, you can manually enter its name without selecting an option"
                      }
                    >
                      <InfoRounded />
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              label={"Industry (IT, Texttile, Communication etc) *"}
              helperText={"Industry is required"}
            />

            {/* Social Media*/}
            <FormTextField
              control={control}
              name={"jp_social_media_links"}
              defaultValue={preloadedData && preloadedData.jp_social_media_links}
              fullWidth
              label={"Social media links (comma separated)"}
              helperText={false}
            />
          </Box>
          <FormTextField
            control={control}
            name={"jp_about_company"}
            label={"Add description about the company *"}
            helperText={"Company description is required"}
            defaultValue={preloadedData && preloadedData.jp_about_company}
            multiline
            rows={8}
          />
        </Box>
        <LoadingButton
          loading={mutationState}
          color="primary"
          variant="contained"
          type="submit"
          sx={{ marginTop: 2 }}
          startIcon={<Send />}
        >
          <Span>{title.includes("Edit") ? "Update" : "Add"}</Span>
        </LoadingButton>
      </form>
    </ContentBox>
  );
}

export default AddCompanyForm;
