import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Icon, InputAdornment, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getCompanyNames } from "app/api/company_api";
import Editor from "app/components/editor/Editor";
import ControlledAutocompleteMulti from "app/components/form-components/ControlledAutocompleteMulti";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import FormAutocompleteMulti from "app/components/form-components/FormAutocompleteMulti";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import FormTextField from "app/components/form-components/FormTextField";
import { Span } from "app/components/Typography";
import { useAddBenefits } from "app/hooks/benefit/useAddBenefits";
import { useGetBenefits } from "app/hooks/benefit/useGetBenefits";
import { useAddSkills } from "app/hooks/skill/useAddSkills";
import useAuth from "app/hooks/useAuth";
import { JobTitleRegex, NumberRegex } from "app/utils/global_constants";
import { convertListToString, getCurrentTimeStamp } from "app/utils/utils";
import dayjs from "dayjs";
import { Fragment, useReducer, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  EmploymentTypes,
  getSelectedCountries,
  getSelectedStates,
  LocationOptions,
  QualificationOptions
} from "../Constants";
import useGetCities from "../hooks/useGetCities";
import useGetCountries from "../hooks/useGetCountries";
import useGetStates from "../hooks/useGetStates";
import AddNewBenefitsDialog from "./AddNewBenefitsDialog";
import AddNewSkillDialog from "./AddNewSkillDialog";
import ContentBox from "./ContentBox";
import PayRange from "./PayRange";
import { cityStateReducer } from "./Reducer/StateCityReducer";
const dateToday = dayjs(new Date());
const dateOneMonthAfter = dateToday.add(1, "month");

function PostJobForm({ mutationFn, preloadedData, skills, title = "", loadingState }) {
  const { handleSubmit, control, watch, setError, clearErrors, setValue } = useForm();
  const { user } = useAuth();
  const [openSkillDialog, setOpenSkillDialog] = useState(false); // For add skill dialog
  const [openBenefitDialog, setOpenBenefitDialog] = useState(false); // For add benefit dialog
  const [jobDescription, setJobDescription] = useState(
    preloadedData ? preloadedData.jp_description : ""
  );

  const jp_country = watch("jp_country");
  const jp_state = watch("jp_state");
  const startDate = watch("jp_start_date");
  const jp_min_exp = watch("jp_min_exp") || "0";
  const jp_max_exp = watch("jp_max_exp") || "0";

  const [CityState, dispatch] = useReducer(cityStateReducer, {
    states: preloadedData?.jp_state || null,
    cities: preloadedData?.jp_city || null
  });

  const location = useLocation();
  const isEditPath = location.pathname.includes("/jobs/listings") ? true : false;
  const isAdminRole = user.role === "SA";

  const handleMaxBlur = (event) => {
    if (Number(jp_max_exp) < Number(jp_min_exp)) {
      setError("jp_max_exp", { type: "manual", message: "Max exp must be greater than min exp" });
    } else if (!NumberRegex.test(jp_max_exp)) {
      setError("jp_max_exp", {
        type: "manual",
        message: "Enter a valid number "
      });
    } else {
      clearErrors("jp_max_exp");
    }
  };

  const handleMinBlur = (event) => {
    if (!NumberRegex.test(jp_min_exp)) {
      setError("jp_min_exp", {
        type: "manual",
        message: "Enter a valid number "
      });
    } else {
      clearErrors("jp_min_exp");
    }
  };

  const selectedCountries = getSelectedCountries(isEditPath, preloadedData, jp_country);
  const countries = useGetCountries();
  const states = useGetStates(selectedCountries); // The states shown depend on the selected country
  const selectedStates = getSelectedStates(states, jp_state);
  const cities = useGetCities(selectedStates); // The cities shown depend on the selected states

  //Get list of companies
  const { data: companies } = useQuery({
    queryKey: ["company_name_list"],
    queryFn: () => getCompanyNames(0),
    staleTime: Infinity
  });

  // Add new skills
  const { mutate: addSkill, isPending: isAdding, data: newSkills } = useAddSkills();

  // Add new benefits
  const { mutate: addBenefits, data: newBenefits } = useAddBenefits();

  // Get Benefit list
  const { data: benefits } = useGetBenefits({ keys: [newBenefits] });

  // Track min salary to check if max salary > min salary
  const minSalary = watch("jp_minimum_salary", 0);

  const savedBenefits = preloadedData
    ? preloadedData.Benefits
      ? preloadedData.Benefits.map(({ jp_id, jp_benefit }) => ({
          jp_id,
          label: jp_benefit
        }))
      : []
    : [];

  const onSubmitData = (data) => {
    const JobPost = {
      ...data,
      jp_location: convertListToString(data.jp_location),
      jp_benefits: convertListToString(data.jp_benefits),
      jp_required_skills: convertListToString(data.jp_required_skills),
      jp_state: convertListToString(data.jp_state),
      jp_country: convertListToString(data.jp_country),
      jp_city: convertListToString(data.jp_city),
      jp_start_date: data.jp_start_date.toISOString().split("T")[0],
      jp_end_date: data.jp_end_date.toISOString().split("T")[0],
      jp_is_visible: false,
      jp_qualification: data.jp_qualification.label,
      jp_type: data.jp_type.label,
      jp_started_at: getCurrentTimeStamp(),
      jp_company_id: isAdminRole ? data.jp_company_name.id : user.id,
      jp_company_name: isAdminRole ? data.jp_company_name.label : user.name,
      jp_role: user.role
    };

    if (location.pathname.includes("/jobs/listings")) {
      JobPost.jp_updated_at = getCurrentTimeStamp();
      JobPost.jp_updated_by = user.id;
    }

    if (location.pathname.includes("/jobs/postjob")) {
      JobPost.jp_created_at = getCurrentTimeStamp();
      JobPost.jp_created_by = user.id;
    }

    mutationFn({ data: JobPost });
  };

  const filterOptions = (options, state) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  return (
    <Fragment>
      <AddNewSkillDialog
        open={openSkillDialog}
        onClose={() => setOpenSkillDialog(false)}
        addFn={addSkill}
        loading={isAdding}
      />
      <AddNewBenefitsDialog
        open={openBenefitDialog}
        onClose={() => setOpenBenefitDialog(false)}
        addFn={addBenefits}
        loading={false}
      />
      <ContentBox>
        <h1>{title}</h1>
        <form onSubmit={handleSubmit(onSubmitData)} onError={() => null}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <h2>Job Title</h2>
            <Box display={"flex"} gap={3}>
              <FormTextField
                control={control}
                name={"jp_title"}
                defaultValue={preloadedData ? preloadedData.jp_title : ""}
                label={"Job Title *"}
                style={{ flex: 1 }}
                rules={{
                  pattern: { value: JobTitleRegex, message: "Please enter valid job title" }
                }}
                helperText={"Title is required"}
              />
              {/** For Admin role when posting a job we need to show company dropdown */}
              {/** But when updating job details, we disable company dropdown  */}
              {isAdminRole && companies && (
                <FormAutocomplete
                  control={control}
                  name={"jp_company_name"}
                  label={"Company *"}
                  defaultValue={preloadedData?.company.jp_company_name}
                  values={companies.map((company, index) => ({
                    id: company.jp_id,
                    label: company.jp_company_name
                  }))}
                  filterOptions={filterOptions}
                  disabled={isEditPath ? true : false}
                  helperText={"Company name is required"}
                />
              )}
            </Box>
            <h2>Start and End Date</h2>
            <Box gap={4} display={"flex"}>
              <FormDatePicker
                defaultValue={preloadedData ? preloadedData.jp_start_date : dateToday}
                control={control}
                name={"jp_start_date"}
                minDate={isEditPath ? dayjs(preloadedData.jp_start_date) : dateToday}
                label={"Start Date"}
              />
              <FormDatePicker
                defaultValue={preloadedData ? preloadedData.jp_end_date : dateOneMonthAfter}
                control={control}
                name={"jp_end_date"}
                minDate={
                  isEditPath
                    ? dayjs(preloadedData.jp_start_date)
                    : startDate
                    ? dayjs(startDate)
                    : undefined
                }
                label={"End Date"}
              />
            </Box>
            <h2>Experience Requirements</h2>
            {/* Experience Type */}
            <Box display={"flex"} alignItems={"center"} gap={2} sx={{ height: "100px" }}>
              <FormTextField
                control={control}
                name={"jp_min_exp"}
                label={"Min experience (years) "}
                onBlur={handleMinBlur}
                defaultValue={preloadedData ? preloadedData.jp_min_exp : "0"}
              />

              <Typography alignSelf={"center"} color={"gray"} fontWeight={"bold"}>
                To
              </Typography>
              <FormTextField
                control={control}
                onBlur={handleMaxBlur}
                name={"jp_max_exp"}
                defaultValue={preloadedData ? preloadedData.jp_max_exp : "0"}
                label={"Max experience (years) "}
              />
            </Box>
            <h2>Qualification Requirements</h2>
            <Box gap={4} display={"flex"} alignItems="start" sx={{ flex: "column" }}>
              {/* Employment Type */}
              <FormAutocomplete
                control={control}
                name={"jp_type"}
                label={"Employment Type *"}
                defaultValue={preloadedData ? preloadedData.jp_type : ""}
                values={EmploymentTypes}
                helperText={"Employment type is required"}
              />
              {/* Qualification */}
              <FormAutocomplete
                control={control}
                defaultValue={preloadedData ? preloadedData.jp_qualification : ""}
                name={"jp_qualification"}
                label={"Highest Education Level *"}
                values={QualificationOptions}
                helperText={"Education level is required"}
              />
              {/* Required Degree */}
              <FormTextField
                control={control}
                name={"jp_required_degree"}
                defaultValue={preloadedData ? preloadedData.jp_required_degree : ""}
                label={"Required Degree *"}
                helperText={"Degree is required"}
              />
            </Box>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <h2>Skills Requirements</h2>
              <Button
                variant={"outlined"}
                endIcon={<Add />}
                size="small"
                onClick={() => setOpenSkillDialog(true)}
                style={{ paddingY: 0, maxHeight: "30px" }}
              >
                <Typography variant="body2">Add Skill</Typography>
              </Button>
            </Box>
            <FormAutocompleteMulti
              name="jp_required_skills"
              control={control}
              label={"Required Skills *"}
              defaultValue={preloadedData ? preloadedData.jp_required_skills : null}
              isOptionEqualToValue={(option, value) => {
                return option.jp_id === value.jp_id;
              }}
              filterOptions={filterOptions}
              values={
                skills
                  ? skills.map((skill) => ({ jp_id: skill.jp_id, label: skill.jp_skill }))
                  : [{ jp_id: 0, label: "Loading..." }]
              }
              helperText={"Skills is required"}
            />
            <h2>Payment Information</h2>
            <Box gap={4}>
              <PayRange>
                <FormTextField
                  name="jp_minimum_salary"
                  label="Minimum Pay Range (Yearly) *"
                  defaultValue={preloadedData ? preloadedData.jp_minimum_salary : ""}
                  type="number"
                  helperText="Starting salary is required"
                  control={control}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>
                  }}
                />
                <span>To</span>
                <FormTextField
                  control={control}
                  name="jp_maximum_salary"
                  defaultValue={preloadedData ? preloadedData.jp_maximum_salary : ""}
                  type="number"
                  helperText="Maximum salary is required"
                  label="Maximum Pay Range (Yearly) *"
                  rules={{
                    validate: (value) =>
                      parseInt(value, 10) > parseInt(minSalary, 10) ||
                      "Maximum salary must be greater than minimum salary"
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>
                  }}
                />
              </PayRange>
            </Box>
            <h2>Location</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormAutocompleteMulti
                  control={control}
                  defaultValue={preloadedData ? preloadedData.jp_location : ""}
                  name={"jp_location"}
                  label={"Location Type *"}
                  helperText={"Location is required"}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  values={LocationOptions}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Country */}
                <FormAutocompleteMulti
                  control={control}
                  name={"jp_country"}
                  defaultValue={preloadedData && preloadedData.jp_country}
                  label={"Country *"}
                  onValueSelected={(value) => {
                    dispatch({ type: "UPDATE_STATE", payload: { country: value } });
                  }}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  values={countries}
                  helperText={"Country is required"}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledAutocompleteMulti
                  control={control}
                  value={CityState.states}
                  name={"jp_state"}
                  label={"State *"}
                  onValueSelected={(value) => {
                    dispatch({ type: "SET_STATE", payload: { states: value } });
                    dispatch({ type: "UPDATE_CITY", payload: { states: value } });
                  }}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  helperText={"State is required"}
                  values={states}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledAutocompleteMulti
                  control={control}
                  value={CityState.cities}
                  name={"jp_city"}
                  label={"City *"}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  onValueSelected={(value) =>
                    dispatch({ type: "SET_CITY", payload: { cities: value } })
                  }
                  helperText={"City is required"}
                  values={cities}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <h2>Benefits</h2>
              <Button
                variant={"outlined"}
                endIcon={<Add />}
                size="small"
                onClick={() => setOpenBenefitDialog(true)}
                style={{ paddingY: 0, maxHeight: "30px" }}
              >
                <Typography variant="body2">Add Benefit</Typography>
              </Button>
            </Box>
            <Box>
              <FormAutocompleteMulti
                control={control}
                name={"jp_benefits"}
                defaultValue={savedBenefits}
                label={"Job Benefits *"}
                helperText={"Job benefits is required"}
                isOptionEqualToValue={(option, value) => {
                  return option.jp_id === value.jp_id;
                }}
                values={
                  benefits
                    ? benefits.map((benefit) => ({
                        jp_id: benefit.jp_id,
                        label: benefit.jp_benefit
                      }))
                    : []
                }
              />
            </Box>
            <h2>Provide a Job Description</h2>
            <Box mb={2}>
              <Controller
                name="jp_description"
                defaultValue={preloadedData?.jp_description}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Editor
                    defaultValue={jobDescription}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Box>
            {/* <FormTextField
              name={"jp_description"}
              label={"Add job description *"}
              defaultValue={preloadedData ? preloadedData.jp_description : ""}
              control={control}
              helperText={"Job description is required"}
              multiline
              fullWidth
              rows={10}
              sx={{ mb: 4 }}
            /> */}
          </Grid>

          <LoadingButton color="primary" variant="contained" type="submit" loading={loadingState}>
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>
              {title.includes("Edit") ? "Update" : "Submit"}
            </Span>
          </LoadingButton>
        </form>
      </ContentBox>
    </Fragment>
  );
}

export default PostJobForm;
