import { EmailSharp, LocationCity, Phone, SchoolOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Grid, Tab } from "@mui/material";
import { H1 } from "app/components/Typography";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import useUpdateCandidate from "app/hooks/candidate/useUpdateCandidate";
import useAuth from "app/hooks/useAuth";
import { convertToBase64 } from "app/utils/utils";
import AddCandidateForm from "app/views/candidate/shared/AddCandidateForm";
import { Fragment } from "react";
import ChangePassword from "./ChangePassword";
import IconText from "./IconText";
const IconStyle = { color: "#222a45" };

function CandidateProfile({ tab, changeTab, handleSuccess, handleError }) {
  const { user, updateUser } = useAuth();

  const onUpdateSuccess = (res) => {
    handleSuccess(true);
  };

  const onUpdateError = (error) => {
    handleError(true);
    handleSuccess(false);
  };

  const {
    data: updateResult,
    mutate: updateCandidate,
    isPending: isUpdating
  } = useUpdateCandidate(user.id, onUpdateSuccess, onUpdateError, (data) => {
    const updatedUser = data.candidate;
    updateUser({
      role: "candidate",
      name: updatedUser.jp_candidate_name,
      email: updatedUser.jp_email,
      id: updatedUser.jp_id
    });
  });

  const { data: candidate, isFetched: isCandidateFetched } = useGetCandidate(
    user.id,
    tab,
    isUpdating,
    updateResult?.candidate?.jp_updated_at
  );

  const profile = candidate && candidate.jp_photo ? candidate.jp_photo.data : "";

  return (
    <Fragment>
      <Box
        sx={{ backgroundColor: "skyblue" }}
        color={"lightblue"}
        minHeight={"200px"}
        maxHeight={"300px"}
        width={"100%"}
      ></Box>
      <Box paddingLeft={"50px"}>
        <Avatar
          sx={{
            width: "150px",
            height: "150px",
            zIndex: "4",
            marginTop: "-100px",
            border: "8px solid white"
          }}
          src={`data:image/jpeg;base64,${convertToBase64(profile)}`}
        />
        {isCandidateFetched && (
          <Grid container gap={4}>
            <Grid item xs={2}>
              <H1 marginBottom={"30px"} sx={{ wordWrap: "break-word" }}>
                {candidate.jp_candidate_name}
              </H1>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                {candidate.jp_address && candidate.jp_city && candidate.jp_state && (
                  <IconText
                    icon={<LocationCity />}
                    content={`${candidate.jp_address}, ${candidate.jp_city}, ${candidate.jp_state}`}
                  />
                )}
                <IconText icon={<EmailSharp sx={IconStyle} />} content={candidate.jp_email || ""} />
                <IconText icon={<Phone sx={IconStyle} />} content={candidate.jp_contactno} />
                {candidate.jp_qualification && candidate.jp_degree_name && (
                  <IconText
                    icon={<SchoolOutlined sx={IconStyle} />}
                    content={`${candidate.jp_qualification} ${candidate.jp_degree_name}`}
                  />
                )}
              </Box>
            </Grid>
            <Grid item justifyContent={"center"} xs={9}>
              <Box>
                <TabContext value={tab}>
                  <TabList onChange={changeTab}>
                    <Tab label="About" value={"1"} />
                    <Tab label="Edit" value={"2"} />
                    <Tab label="Security" value={"3"} />
                  </TabList>
                  <TabPanel value={"1"}>
                    {candidate.jp_about_me
                      ? candidate.jp_about_me
                      : "Add your bio in the 'Edit' section"}
                  </TabPanel>
                  <TabPanel value={"2"}>
                    {candidate && (
                      <AddCandidateForm
                        mutationFn={updateCandidate}
                        preloadedData={candidate}
                        title={"Edit Your Profile"}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={"3"}>
                    <ChangePassword />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Fragment>
  );
}

export default CandidateProfile;
