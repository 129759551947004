import axios from "axios";

export async function addCompany(company) {
  const res = await axios.post("/company", company);
  return res;
}

export async function deleteCompany(id) {
  return axios.patch("/company/" + id, { jp_is_deleted: true }).catch((err) => {
    throw Error(err);
  });
}

export async function restoreCompany(id) {
  return axios.patch("/company/" + id, { jp_is_deleted: false }).catch((err) => {
    throw Error(err);
  });
}

export async function getCompanyLogo(id) {
  const response = await axios.get("/company/logo", { params: { id: id } });
  return response.data;
}

export async function updateCompany(company, id) {
  const response = await axios.put("/company/" + id, company, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  console.log("🚀 ~ updateCompany ~ response:", response.data);
  return response.data;
}

export async function isCompanyExists(email) {
  const response = await axios.get("/company/isExists", { params: { email } });
  return response.data;
}

export async function getCompanyList(sort, column, limit, offset, searchQuery, id) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery,
    orderBy: column,
    orderByValue: sort,
    id: id
  };
  const response = await axios.get("/company", { params: queryParams });
  const data = response.data.rows;
  return { count: response.data.count, data: data };
}

export async function getCompanyNames(isDeleted = 0) {
  const response = await axios.get("/company/list", { params: { isDeleted: isDeleted } });
  return response.data;
}

export async function getDeletedCompanies(sort, column, limit, offset, searchQuery, id) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery,
    orderBy: column,
    orderByValue: sort,
    id: id
  };
  const response = await axios.get("/company/deleted", { params: queryParams });
  return response.data;
}

export async function getCompany(id) {
  const queryParams = {
    id: id
  };
  const response = await axios.get("/company", { params: queryParams });
  return response.data;
}

export async function updateCompanyPassword(id, newPass, oldPass, reset = false) {
  try {
    const response = await axios.patch(
      "/company/change-password",
      {
        id,
        newPass,
        oldPass,
        reset
      },
      { "Content-Type": "application/json" }
    );
    return response;
  } catch (e) {
    return e;
  }
}

export async function getCompanyId(email) {
  try {
    const response = await axios.get("/company/get-id-by-email", { params: { email } });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
