import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, styled, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import cover from "../../../assets/illustrations/candidate_login_cover.svg";

import { H1, Paragraph } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import ChangeLoginLink from "./shared/ChangeLoginLink";
import PasswordTextField from "./shared/PasswordTextField";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex"
}));

const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)"
}));

const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1A2038",
  minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

// initial login credentials
const initialValues = {
  email: "",
  password: "",
  remember: false
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be 8 characters length")
    .required("Password is required!"),
  email: Yup.string().email("Invalid Email address").required("Email is required!")
});

export default function JwtLoginCandidate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [responseErrors, setResponseErrors] = useState({
    userNotFound: {
      status: false,
      message: "Could not find user with this email address"
    },
    passwordIncorrect: {
      status: false,
      message: "Incorrect password. Please enter correct password"
    },
    suspendedAccount: {
      status: false,
      message: "Cannot login. Account suspended."
    }
  });

  const { login } = useAuth();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const type = "candidate";
      const res = await login(values.email, values.password, type);

      if (!res.success) {
        if (res.message === "Could not find user") {
          setResponseErrors((prev) => ({ ...prev, userNotFound: { status: true } }));
        }
        if (res.message.includes("Incorrect password")) {
          setResponseErrors((prev) => ({ ...prev, passwordIncorrect: { status: true } }));
        }
        if (res.message === "Account suspended. Cannot login.") {
          setResponseErrors((prev) => ({ ...prev, suspendedAccount: { status: true } }));
        }
        return;
      }

      navigate("/");
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const registerLink = "/session/signup/candidate";

  return (
    <StyledRoot>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <div className="img-wrapper">
              <a
                href="https://www.freepik.com"
                title="Image from Freepick"
                target="_blank"
                rel="noreferrer"
              >
                <img src={cover} width="100%" alt="cover from freepik" />
              </a>
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <H1 mb={4}>Candidate LogIn</H1>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={(event) => {
                        handleChange(event);
                        if (responseErrors.userNotFound || responseErrors.suspendedAccount) {
                          setResponseErrors((prev) => ({
                            ...prev,
                            userNotFound: false,
                            suspendedAccount: false
                          }));
                        }
                      }}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: !responseErrors.userNotFound.status && 3 }}
                    />

                    {responseErrors.userNotFound.status && (
                      <Typography sx={{ marginBottom: 3, color: "red" }}>
                        No user with this email exists
                      </Typography>
                    )}

                    <PasswordTextField
                      value={values.password}
                      name={"password"}
                      onChange={(event) => {
                        handleChange(event);
                        if (responseErrors.passwordIncorrect) {
                          setResponseErrors((prev) => ({ ...prev, passwordIncorrect: false }));
                        }
                      }}
                      helperText={touched.password && errors.password}
                      errors={Boolean(errors.password && touched.password)}
                      onBlur={handleBlur}
                    />

                    {responseErrors.passwordIncorrect.status && (
                      <Typography sx={{ marginBottom: 3, color: "red" }}>
                        The password that you've entered is incorrect.
                      </Typography>
                    )}

                    {responseErrors.suspendedAccount.status && (
                      <Typography sx={{ marginBottom: 2, color: "red" }}>
                        Login restricted. Your account is suspended.
                      </Typography>
                    )}

                    <FlexBox justifyContent="space-between">
                      <NavLink
                        to="/session/forgot-password"
                        style={{ color: theme.palette.primary.main }}
                        state={{ role: "candidate" }}
                      >
                        Forgot password?
                      </NavLink>
                    </FlexBox>

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}
                    >
                      Login
                    </LoadingButton>

                    {!location.pathname.includes("admin") && (
                      <Paragraph>
                        Don't have an account?
                        <NavLink
                          to={registerLink}
                          style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                        >
                          Register
                        </NavLink>
                      </Paragraph>
                    )}
                    <ChangeLoginLink />
                  </form>
                )}
              </Formik>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
  );
}
