import { CountryCodeMap } from "app/views/candidate/shared/Constants";
import { City, Country, State } from "country-state-city";

export const InitialState = {
  country: null,
  state: null,
  city: null,
  countryOptions: Country.getAllCountries().map((country) => ({
    id: country.isoCode,
    label: country.name
  })),
  stateOptions: [],
  cityOptions: []
};

export function createInitialStateFromDB(values) {
  const country = { id: CountryCodeMap[values.jp_country], label: values.jp_country };
  let statesOfCountry = State.getStatesOfCountry(country.id);
  const state = statesOfCountry.find((state) => state.name === values.jp_state);
  const citiesOfState = City.getCitiesOfState(country.id, state.isoCode);
  const city = citiesOfState.find((city) => city.name === values.jp_city);

  return {
    country: country,
    state: { id: state.isoCode, label: state.name },
    city: { id: `${city.stateCode}-${city.name}`, label: city.name },
    countryOptions: Country.getAllCountries().map((country) => ({
      id: country.isoCode,
      label: country.name
    })),
    stateOptions: statesOfCountry.map((state) => ({ id: state.isoCode, label: state.name })),
    cityOptions: citiesOfState.map((city) => ({
      id: `${city.stateCode}-${city.name}`,
      label: city.name
    }))
  };
}

export const CompanyLocationActions = {
  SET_COUNTRY: "SET_COUNTRY",
  SET_STATE: "SET_STATE",
  SET_CITY: "SET_CITY",
  UPDATE_STATE_CITY: "UPDATE_STATE_CITY",
  UPDATE_STATE_OPTIONS: "UPDATE_STATE_OPTIONS",
  UPDATE_CITY: "UPDATE_CITY"
};

export const CompanyLocationReducer = (prevState, action) => {
  switch (action.type) {
    case CompanyLocationActions.SET_COUNTRY:
      return {
        ...prevState,
        country: action.payload.country
      };
    case CompanyLocationActions.SET_STATE:
      return { ...prevState, state: action.payload.state };
    case CompanyLocationActions.SET_CITY:
      return { ...prevState, city: action.payload.city };
    case CompanyLocationActions.UPDATE_STATE_OPTIONS:
      const statesOfSelectedCountry = action.payload.country
        ? State.getStatesOfCountry(action.payload.country.id).map((state) => ({
            id: state.isoCode,
            label: state.name
          }))
        : [];
      return { ...prevState, state: null, stateOptions: statesOfSelectedCountry };
    case CompanyLocationActions.UPDATE_CITY:
      const citiesOfSelectedState = action.payload.state
        ? City.getCitiesOfState(prevState.country.id, action.payload.state.id).map((city) => ({
            id: `${city.stateCode}-${city.name}`,
            label: city.name
          }))
        : [];
      return { ...prevState, city: null, cityOptions: citiesOfSelectedState };
    default:
      return prevState;
  }
};
