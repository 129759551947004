export const filterState = {
  type: [],
  exp: [],
  skill: 0,
  location: ""
};

export const filterAction = {
  SET_TYPE: "SET_TYPE",
  SET_EXP: "SET_EXP",
  SET_SKILL: "SET_SKILL",
  SET_LOCATION: "SET_LOCATION"
};

export function filterReducer(state, action) {
  switch (action.type) {
    case filterAction.SET_TYPE:
      return { ...state, type: action.payload.map((type) => type.label) };
    case filterAction.SET_EXP:
      return { ...state, exp: action.payload?.value };
    case filterAction.SET_SKILL:
      return { ...state, skill: action.payload };
    case filterAction.SET_LOCATION:
      return { ...state, location: action.payload };
    default:
  }
}
